/* src/App.css */
/* ----------------------------------------------------------------------------------------------------------------------- general */
body, html {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}

.app {
  /* max-width: 1200px; */
  margin: 0 auto;
  /* padding: 20px; */
  padding-top: 60px; /* Add padding to the main content to avoid content hiding under the fixed header */
}

button {
  background-color: rgba(83, 119, 236, 1);
  color: white;
  padding: 1vh 2vh;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease;
  font-size: 2.5vh;
}

button:hover {
  background-color: rgb(47, 68, 133);
}

h1 {
  color: rgba(83, 119, 236, 1);
}

h2 {
  font-size: 4vh;
}

.background-image {
  background-image: url('whisps.png');
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Place it behind other content */
}
/* ----------------------------------------------------------------------------------------------------------------------- header */
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  /* padding: 1vh 0; */
  z-index: 100;
  font-size: 2.25vh;
}

.header nav ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
}

.header nav a {
  color: white;
  text-decoration: none;
  transition: color 0.5s ease;
}

.header nav a:hover {
  color: rgba(83, 119, 236, 1);
  text-decoration: none;
}

.header .headerlogo {
  color: rgba(83, 119, 236, 1);
  font-weight: bold;
}

.header .headerlogo:hover {
  color: rgb(255, 255, 255);
  font-weight: bold;
}
/* ----------------------------------------------------------------------------------------------------------------------- hero */ 
.hero {
  /* background-image: url('whisps.png'); */
  background-size: cover;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 1);
  text-align: center;
  /* filter: brightness(0); Adjust the brightness value (0 to 1) */
}

.hero-content h1 {
  font-size: 5vh;
}

.hero-content p {
  font-size: 2.5vh;
}
/* ----------------------------------------------------------------------------------------------------------------------- services */
.services {
  padding: 4vh 0;
  /* background-color: #f5f5f5; */
}

.services h2 {
  text-align: center;
  margin-bottom: 3vh;
}

.services h3 {
  text-align: center;
}

.service-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 2vh;
  border-radius: 5vh;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin: 2vh;
  width: 26%;
  text-align: justify;
}
/* ----------------------------------------------------------------------------------------------------------------------- about */
.about {
  padding: 4vh 0;
  background-color: #f5f5f5;
  text-align: center;
}
/* ----------------------------------------------------------------------------------------------------------------------- contact */
.contact {
  padding: 4vh 0;
  padding-bottom: 9vh;
  text-align: center;
}

.contact form {
  max-width: 400px;
  margin: 0 auto;
}

.contact label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.contact input,
.contact textarea {
  width: 100%;
  padding: 1vh;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
/* ----------------------------------------------------------------------------------------------------------------------- footer */
.footer {
  /* padding: 2vh; */
  background-color: #333;
  color: white;
  text-align: center;
  /* margin-top: 2vh; */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  /* padding: 1vh 0; */
  font-size: 2vh;
}